import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { State } from './reducer'
import { MAP_DISTRICT } from '../enums/common'

type MoneyForm = {
	district: MAP_DISTRICT | null
	paymentAmount: number | string
	isRecurring: boolean
	participationInterest: boolean
}

type TreeForm = {
	district: MAP_DISTRICT | null
	paymentAmount: number
	dedication: string
	participationInterest: boolean
	isGift: string
}

export type ContributeState = {
	money: MoneyForm | null
	tree: TreeForm | null
}

const initialState: ContributeState = {
	money: null,
	tree: null
}

export const contributeSlice = createSlice({
	name: 'contribute',
	initialState,
	reducers: {
		clear: (state) => {
			state.money = null
			state.tree = null
		},
		saveMoneyForm: (state, action: PayloadAction<MoneyForm>) => {
			state.money = action.payload
		},
		saveTreeForm: (state, action: PayloadAction<TreeForm>) => {
			state.tree = action.payload
		}
	}
})

export const getMoneyContribute = (state: State) => state.contribute.money
export const getTreeContribute = (state: State) => state.contribute.tree

export const contributeActions = {
	...contributeSlice.actions,
}
