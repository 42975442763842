import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { State } from './reducer'
import { StateType } from '../types/state'
import { ArticleDetail } from '../types/data'

export type PagesState = {
	detail: StateType<ArticleDetail>
}

const initialState: PagesState = {
	detail: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export const pagesSlice = createSlice({
	name: 'pages',
	initialState,
	reducers: {
		loadPageDetail: (state: PagesState, action: PayloadAction<ArticleDetail>) => {
			state.detail.data = action.payload
		}
	}
})

export const getPageDetail = (state: State) => state.pages.detail

export const pagesActions = {
	...pagesSlice.actions
}
