import { combineReducers } from 'redux'

import { MenuState, menuSlice } from './menu'
import { BlogPostsState, blogPostsSlice } from './blog'
import { PagesState, pagesSlice } from './pages'
import { contributeSlice, ContributeState } from './contribute'
import { partnersSlice, PartnersState } from './partners'
import { settingsSlice, SettingsState } from './settings'
import { mapSlice, MapState } from './map'
import { formsSlice, FormsState } from './forms'

export type State = {
	menu: MenuState,
	blogPosts: BlogPostsState
	pages: PagesState
	contribute: ContributeState
	partners: PartnersState
	settings: SettingsState
	map: MapState
	forms: FormsState
}

const rootReducer = combineReducers({
	menu: menuSlice.reducer,
	blogPosts: blogPostsSlice.reducer,
	pages: pagesSlice.reducer,
	contribute: contributeSlice.reducer,
	partners: partnersSlice.reducer,
	settings: settingsSlice.reducer,
	map: mapSlice.reducer,
	forms: formsSlice.reducer
})

export default rootReducer
